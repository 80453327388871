import * as firebase from "firebase/app";
import "firebase/storage";

export const getEndorsementImageURL = async filename => {
  try {
    const BUCKET = process.env.VUE_APP_ENDORSEMENT_BUCKET;
    const storage = firebase.storage();
    const storageRef = storage.ref(BUCKET);
    const imageRef = storageRef.child(filename);
    const url = await imageRef.getDownloadURL();
    return url;
  } catch (error) {
    console.error(error);
  }
};
